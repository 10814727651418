/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h4: "h4",
    p: "p",
    strong: "strong",
    a: "a",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components), {Admonition} = _components;
  if (!Admonition) _missingMdxReference("Admonition", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h4, null, "Step 1. Create a payment instruction"), "\n", React.createElement(_components.p, null, "Use our gateway to configure the surcharging options, tip options, and tax amounts."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " If you’re unsure about how to configure these options, contact your Payroc Integration Engineer.")), "\n", React.createElement(_components.p, null, "Send a POST request to the Devices test endpoint:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/devices/%7BserialNumber%7D/payment-instructions"
  }, "https://api.uat.payroc.com/v1/devices/{serialNumber}/payment-instructions")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example request")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"operator\": \"jbloggs\",\n    \"processingTerminalId\": \"1021\",\n    \"order\": {\n        \"orderId\": \"4fd4-99bc\",\n        \"currency\": \"USD\",\n        \"amount\": 1000,\n        \"orderBreakdown\": {\n            \"subtotalAmount\": 1000\n        }\n    },\n    \"customizationOptions\": {\n        \"entryMethod\": \"deviceRead\"\n    },\n    \"autoCapture\": true,\n    \"processAsSale\": false\n}\n")), "\n", React.createElement(_components.h4, null, "Step 2. Retrieve the status of the payment instruction"), "\n", React.createElement(_components.p, null, "Send a GET request to the Payment Instructions test endpoint:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/payment-instructions/%7BpaymentInstructionId%7D"
  }, "https://api.uat.payroc.com/v1/payment-instructions/{paymentInstructionId}")), "\n", React.createElement(_components.p, null, "If the status of the payment instruction is ", React.createElement("code", null, "inProgress"), ", our gateway waits up to a minute for the status to change before it returns a response. Send GET requests until the value for ", React.createElement(_components.strong, null, "status"), " changes to ", React.createElement("code", null, "completed"), "."), "\n", React.createElement(Admonition, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note:"), " If the status of the payment instruction is ", React.createElement("code", null, "failed"), " or ", React.createElement("code", null, "canceled"), ", contact your Payroc Integration Engineer.")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"paymentInstructionId\": \"3743a9165d134678a9100ebba3b29597\",\n  \"status\": \"completed\",\n  \"link\": {\n    \"rel\": \"payment\",\n    \"method\": \"GET\",\n    \"href\": \"https://api.payroc.com/v1/payments/DD6ZDQU7L2\"\n  }\n}\n")), "\n", React.createElement(_components.h4, null, "Step 3. Retrieve the status of the payment instruction"), "\n", React.createElement(_components.p, null, "Send a GET request to the Payment test endpoint:"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://api.uat.payroc.com/v1/payments/%7BpaymentId%7D"
  }, "https://api.uat.payroc.com/v1/payments/{paymentId}")), "\n", React.createElement(_components.p, null, "Use the paymentID that you received in the response from Step 2."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Example response")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n    \"operator\": \"XYZ\",\n    \"terminal\": 1021,\n    \"order\": {\n        \"orderId\": \"4fd4-99bc\",\n        \"description\": \"Payment Instruction\",\n        \"currency\": \"USD\",\n        \"totalAmount\": 10.00,\n        \"orderBreakdown\": {\n            \"subtotalAmount\": 10.00,\n            \"surcharge\": {\n                \"bypass\": \"true\"\n            },\n            \"taxes\": [\n                {\n                    \"name\": \"TAX10\",\n                    \"rate\": 10.0\n                }\n            ],\n            \"tip\": {}\n        }\n    },\n    \"customizationOptions\": {\n        \"entryMethod\": \"DEVICE_READ\"\n    },\n    \"customer\": {\n        \"name\": \"First Last\",\n        \"dateOfBirth\": \"2023-01-01\",\n        \"referenceNumber\": \"ABCDEFGH\",\n        \"billingAddress\": {\n            \"line1\": \"billing Addr Line1\",\n            \"line2\": \"billing Addr Line2\",\n            \"city\": \"billCity\",\n            \"state\": \"AZ\",\n            \"country\": \"US\",\n            \"postalCode\": 12345\n        },\n        \"shippingAddress\": {\n            \"name\": \"shipping recipient name\",\n            \"line1\": \"shipping Addr Line1\",\n            \"line2\": \"shipping Addr Line2\",\n            \"city\": \"shipCity\",\n            \"state\": \"AZ\",\n            \"country\": \"US\",\n            \"postalCode\": \"SH12345\"\n        },\n        \"email\": \"email@domain.com\",\n        \"phone\": \"+353891234567\",\n        \"notificationLanguage\": \"en\"\n    },\n    \"autoCapture\": true,\n    \"processAsSale\": false\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
